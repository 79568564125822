import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "white",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "inherit",
      d: "M3 21.9999V3.99988H6V1.99988H8V3.99988H16V1.99988H18V3.99988H21V21.9999H3ZM5 19.9999H19V9.99988H5V19.9999ZM5 7.99988H19V5.99988H5V7.99988Z"
    }, null, -1)
  ])))
}
export default { render: render }